import React from 'react'
import logo from '../assets/icons/logo2.png'
import Dropdown from './DropDown'
import { logout } from '../core/firebase'

type INavbar = {
  userEmail?: string
}
const Navbar = (props: INavbar) => {
  return (
    <div className='w-full p-2 h-16 bg-white text-white flex justify-between '>
      <div className='h-full'>
        <img className='h-full' src={logo} alt="BSC Distribution" />
      </div>
      <div>
        {
          props.userEmail ? <Dropdown
            label={props.userEmail}
            lists={[{ label: 'logout', cb: logout }]}
          /> : ''
        }
      </div>
    </div>
  )
}

export default Navbar