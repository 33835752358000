import React, { useState } from 'react'
import { DelBtn, EditBtn, Header, Theader } from '../../components'
import { userHeader } from '../../constant'
import { Link } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { appStateProxy, deleteCategory } from '../../core/stores'
import { deleteUser } from 'firebase/auth'

const Clients = () => {
  const [editCustomer, setEditCustomer] = useState<{ id: string }>()
  const [modal, setModal] = useState<boolean>()
  const { users } = useSnapshot(appStateProxy)
  return (
    <section className='w-full h-full flex flex-col gap-4'>
      <Header />
      <table className="min-w-full divide-y divide-gray-300 bg-white">
        <Theader labels={userHeader} />
        <tbody className="bg-white">
          {users.map((user, idx) => (
            <tr key={idx} className="even:bg-gray-50">
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{user.avatar && <img className='w-10 border rounded-full aspect-square object-cover' src={user.avatar} alt={user.lastName} />}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.firstName}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.lastName}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.email}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.phoneNumber}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.balance}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                <EditBtn id={user.id} setId={setEditCustomer} setDisplay={setModal} />
                <DelBtn delteMethode={deleteUser} id={user.id} />
              </span></td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  )
}

export default Clients