import React, { useState } from 'react'
import { EditOrder, Modal, Theader } from '../components'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../core/stores'

type ICommande={

}
const CommandeSection = (props:ICommande) => {
  const [modal,setModal]=useState<boolean>()
  const [orderId,setOrderId]=useState<{id:string}>({id:''})
  const {orders,users,deliveries}=useSnapshot(appStateProxy)
  return (
    <section className='w-full flex flex-col bg-white space-y-4 shadow-md'>
      <h3 className='p-2 font-bold text-xl'>Commandes</h3>
      <table className="min-w-full divide-y divide-gray-300 overflow-y-auto  max-h-96  ">
        <Theader labels={['Client',"livreur","Status"]} />
        <tbody className="bg-white">
                {orders.filter((ord)=>ord.status==='En cours'||ord.status==="En attente").slice(0,5).map((order,idx) => (
                  <tr onClick={()=>{setModal((prev:any)=>!prev);setOrderId((prev:any)=>({...prev,id:order.id}))}}  key={idx} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{users.map((user)=>user.id===order.userId&&`${user.firstName} ${user.lastName}`)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{deliveries.map((del)=>del.id===order.deliveryId&&`${del.firstName} ${del.lastName}`)}</td>
                    <td className={`whitespace-nowrap px-3 py-4 text-sm font-bold ${order.status==='En cours'?'text-[#D06E0B]':order.status==='Livrée'?'text-[#0BD06E]':order.status==='Annulée'?'text-[#D00B0B]':'text-gray-900'}`}>{order.status}</td>
                  </tr>
                ))}
              </tbody>
      </table>
      { orders.length === 0 && <div className='w-full p-4 text-center'>Pas de commandes en attente pour le moment.</div>}

      {modal && (
        <Modal>
         <EditOrder id={orderId.id} setId={setOrderId} setDipaly={setModal}/>
        </Modal>
      )}
    </section>
  )
}

export default CommandeSection