import { fsAdd, fsDelete, fsGet, fsListen, fsListenById, fsUpdate } from "../firebase";
import { IConfig } from "../models";

const colName = "configs"

const getConfigs = async (): Promise<IConfig[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenConfigs = (cb: (Configs: IConfig[]) => void): void => {
    fsListen(colName, cb)
}

const listenConfig = (id: string, cb: (Config: IConfig) => void): void => {
    fsListenById(colName, id, cb)
}


const addConfig = async (data: Partial<IConfig>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateConfig = async (id: string, data: Partial<IConfig>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteConfig = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export { getConfigs, addConfig, updateConfig, deleteConfig, listenConfig, listenConfigs } 