export const navLists=[
    {
        label:'Dashboard',
        path:'/dashboard',
        icon:''
    },
    {
        label:'Catégories',
        path:'/dashboard/categories',
        icon:''
    },
    {
        label:'Produits',
        path:'/dashboard/produits',
        icon:''
    },
    {
        label:'Commandes',
        path:'/dashboard/commandes',
        icon:''
    },
    {
        label:'Paiements',
        path:'/dashboard/payments',
        icon:''
    },
    {
        label:'Clients',
        path:'/dashboard/clients',
        icon:''
    },
    {
        label:'Livreurs',
        path:'/dashboard/livreurs',
        icon:''
    },
    {
        label:'Slides',
        path:'/dashboard/slides',
        icon:''
    },
    {
        label:'Publicités',
        path:'/dashboard/publicites',
        icon:''
    },
    {
        label:'Code Promos',
        path:'/dashboard/code-promo',
        icon:''
    },
    {
        label:'Giftcards',
        path:'/dashboard/giftcards',
        icon:''
    },
    {
        label:'Paramètres',
        path:'/dashboard/parametres',
        icon:''
    }
]

export const prodHeader=[
    'Designation',
    'Prix',
    'Quantiter',
    'Image',
    'Status'
]
export const commandeHeader=['Boutique', 'Date','Client','Montant', "Paiement", 'Distance', 'Livreur','Status']
export const  categoriesHeader=['Designation','Description', 'Image','Status']
export const userHeader =['Avatar', 'Nom','Prénom','Email', "Contact", "Solde",""]
export const paymentHeader =['Date', 'Montant', 'methode', 'Nom','Prénom', "Contact", "Status",""]
export const slidesHeader =['Image','Designation','Ordre','Status']
export const adsHeader=['Image', 'Titre' ,'Contact','Order','Status']
export const codePromoHeader=['Designation', 'Reduction', 'Status',`Limite d'utilisateur`,'Limite totale',"Date de limite", "Utilisations"]
export const giftcardsHeader=['Designation', 'Code', 'Passe', 'Montant', 'Date', "Par"]
export const settingsHeader=['Adminstrateur', 'Frais', 'Pays', 'Boutiques']