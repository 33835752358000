import { fsListen, fsListenById } from "../firebase";

const colName = "stats";


const listenStats = (cb: (stats: any[]) => void): void => {
    fsListen(colName, cb)
}
const listenShopStats = (shopId: string, cb: (stats: any) => void): void => {
    fsListenById(colName, shopId, cb)
}

const groupMonthlyStats = (stats: any[]) => {
    const statsByMonth: any = {};
    // console.log(stats)
    // Iterate through each shop
    stats.forEach(shop => {
        const shopId = shop.id;

        // Iterate through each month
        for (const month in shop) {
            if (month !== 'id' && month !== 'updatedAt') {
                // If the month does not exist in the global grouping, initialize it
                if (!statsByMonth[month]) {
                    statsByMonth[month] = {};
                }

                // Add the shop's monthly statistics to the global grouping
                if (!statsByMonth[month][shopId]) {
                    statsByMonth[month][shopId] = 0;
                }
                statsByMonth[month][shopId] += shop[month];
            }
        }
    });

    // Sort the months
    const sortedMonths = Object.keys(statsByMonth).sort((a, b) => {
        // Convert the months to integers for sorting
        return parseInt(a, 10) - parseInt(b, 10);
    });

    // Create a new object with sorted months
    const globalStats: any = {};
    sortedMonths.forEach(month => {
        globalStats[month] = statsByMonth[month];
    });

    const globalStatsArray = sortedMonths.map(month => {
        return Object.values(statsByMonth[month]).reduce((sum: number, value: any) => sum + value, 0);
      });
    
    return {globalStats, globalStatsArray};

}


export {
    listenStats,
    listenShopStats,
    groupMonthlyStats
}