import { messaging } from "./init"
import { getToken, onMessage } from 'firebase/messaging'

const getFcmKey = async () => {
    const granted = await window.Notification.requestPermission();
    if (granted === 'granted') {
        try {
            const token = await getToken(messaging)
            console.log("FCM Token: ", token)
            return token
        } catch (err: any) {
            console.log("Error getting FCM token: ", err.message)
            return null
        }
    }
    return null
}

const listenForgroundNotifications = (cb: any) => {
    onMessage(messaging,  (payload: any) => {
        console.log("Message received. ", payload);
        // ...
        cb(payload)
    });
}

export { getFcmKey, listenForgroundNotifications }