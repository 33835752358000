import React, { useEffect, useState } from 'react'
import { DelBtn, EditBtn, Modal, SpinnerLoader, Theader } from '../../components'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { deleteCountry } from '../../core/stores/country'
import { IConfig } from '../../core/models'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../../core/stores'
import { CSVLink } from 'react-csv';

const Data = () => {
    const { configs } = useSnapshot(appStateProxy)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [shopId, setShopId] = useState<any>('')
    const [data, setData] = useState<any[]>([])
    const { shops, orders, products } = useSnapshot(appStateProxy)
    useEffect(() => {
        const data = calculateOrders()
        // console.log(data)
        setData(data)
    }, [startDate, endDate, shopId, orders, products])


    const calculateOrders = () => {
        const productIds = products.map(product => product.id)
        const filteredOrders = orders.filter(order => {
            // Filtrer par statut "En cours" ou "Livrée"
            // console.log(order.status)
            const validStatus = ['En cours', 'Livrée'].includes(order.status);

            // Filtrer par dates si fournies
            const validStartDate = startDate ? order.createdAt.seconds * 1000 >= startDate : true;
            const validEndDate = endDate ? order.createdAt.seconds * 1000 <= endDate : true;
            const validateShop = shopId ? order.shopId == shopId : true;
            return validStatus && validStartDate && validEndDate && validateShop;
        });

        // console.log(JSON.parse(JSON.stringify(filteredOrders)))

        const orderedProducts: any = {};

        // Parcourir les commandes filtrées
        filteredOrders.forEach(order => {
            // Parcourir les articles de chaque commande
            order.cart.forEach((item: any) => {
                const productId = item.productId;

                // Vérifier si l'ID du produit est dans la liste fournie
                if (productIds.includes(productId)) {
                    // Ajouter la quantité commandée au produit correspondant
                    if (!orderedProducts[productId]) {
                        orderedProducts[productId] = 0;
                    }
                    orderedProducts[productId] += item.quantity;
                }
            });
        });

        return products.map((product) => ({
            code: product.id,
            name: product.name,
            quantity: orderedProducts[product.id] || 0,
            amount: (orderedProducts[product.id] || 0)* product.unitPrice
        }));
    }






    return (
        <section>
            <div className='flex justify-between items-center text-right p-4'>
                <div className='flex gap-8'>

                    <div>
                        <select
                            value={shopId}
                            onChange={(evt) => setShopId(evt.target.value)}
                            name="deliveryId"
                            className=" px-2 py-1 outline-none bg-white border"
                        >
                            <option

                                value={''}
                            >Toute Boutique</option>
                            {shops.map((shop, idx: any) => (
                                <option
                                    key={idx}
                                    value={shop.id}
                                >{shop.name}</option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="">De: </label>
                        <input onChange={(ev) => setStartDate(new Date(ev.target.value))} type='date' className='px-2 py-1 border' />
                    </div>

                    <div>
                        <label htmlFor="">A: </label>
                        <input onChange={(ev) => setEndDate(new Date(ev.target.value))} type='date' className='px-2 py-1 border' />
                    </div>
                </div>
                <div>

                    <CSVLink className='bg-primary px-4 py-1 rounded-md text-white' data={data} headers={["code", "name", "quantity", "amount"]} filename={'ordered_products_' + new Date().getTime() + '.csv'}>
                        Exporter CSV
                    </CSVLink>
                </div>

            </div>
            <table className="min-w-full divide-y divide-gray-300 bg-white">
                <Theader labels={["Code", "Produit", "Quantité", "Montant"]} />
                <tbody className="bg-white">
                    {data.map((product, idx) =>
                        <tr key={idx} className="even:bg-gray-50">
                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.images && <img className='w-10 rounded-full border' src={product.images} alt={product.name} />}</td> */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{product.code}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{product.name}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{product.quantity}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{product.amount}</td>

                        </tr>
                    )}
                </tbody>
            </table>

        </section>
    )
}

export default Data