import { fsAdd, fsDelete, fsGet, fsGetById, fsListen, fsUpdate } from "../firebase";
import { uploadFile } from "../firebase/upload";
import { ICountry } from "../models";

const colName = "countries";

const getCountries = async (): Promise<ICountry[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenCountries = (cb: (Countries: ICountry[]) => void): void => {
    fsListen(colName, cb)
}

const getCountry = (id: string): Promise<ICountry> => {
    const data: any = fsGetById(colName, id)
    return data
}

const addCountry = async (data: Partial<ICountry>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateCountry = async (id: string, data: Partial<ICountry>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteCountry = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}
const uploadCountrytFile=(id:string,file:any)=>{
   return  uploadFile(`/images/countries/${id}`,file)
}

export { getCountries, getCountry, addCountry, updateCountry, deleteCountry, listenCountries,uploadCountrytFile } 
