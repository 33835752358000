import React, { useState } from 'react'
import { DelBtn, EditBtn, Modal, SpinnerLoader, Theader } from '../../components'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { deleteCountry } from '../../core/stores/country'
import { IConfig } from '../../core/models'
import { addConfig } from '../../core/stores/config'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../../core/stores'

const Config = () => {
  const [modal,setModal]=useState<boolean>()
  const [config,setConfig]=useState<Pick<IConfig,"amount"|"name"|"description">>({amount:0,description:'',name:''})
  const [loader,setLoader]=useState<boolean>()
  const {configs}=useSnapshot(appStateProxy)
  
  const changeHandler =(e:any)=>setConfig((prev:any)=>({...prev,[e.target.name]:e.target.value}))
  const cancelHandler =()=>{
    setModal((prev:any)=>!prev)
  }
  const submitHandler=()=>{
    if(!config.amount||!config.description||!config.name||loader)return
    setLoader((prev:any)=>!prev)
    setConfig((prev:any)=>({...prev,amount:+prev.amount}))
    try {
      // addConfig()
    } catch (error) {
      
    }
  }
  return (
    <section>
    <div className='text-right p-4'>
        <button onClick={()=>setModal((prev:any)=>!prev)} className='bg-primary p-4 rounded-md text-white'>Ajouter</button>
    </div>
       <table className="min-w-full divide-y divide-gray-300 bg-white">
    <Theader labels={["Nom","Description","Montant"]} />
    <tbody className="bg-white">
        {configs.map((config,idx)=>
            <tr   key={idx} className="even:bg-gray-50">
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{config.name}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{config.description}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{config.amount}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                  <EditBtn id={''} setId={()=>{''}} setDisplay={setModal}/>
                  <DelBtn  delteMethode={deleteCountry} id={''}  />
                  </span></td>
          </tr>
        )}
    </tbody>
  </table>
  {
    modal&&
    <Modal>
        <section className='bg-white p-4 rounded min-w-[30%] space-y-4'>
            <span className='flex justify-end
            '>
                <XMarkIcon onClick={cancelHandler} className='cursor-pointer w-8 h-8'/>
            </span>
            <div className='flex p-4 items-center'><label className='w-36'>Nom:</label><input className='w-full rounded border-2 4 p-2 'onChange={changeHandler} name='name' value={config.name} type="text" /></div>
            <div className='flex p-4 items-center'><label className='w-36' >Description:</label><input className=' w-full rounded p-2  border-2' onChange={changeHandler}  type="text" name='description' value={config.description} /></div>
            <div className='flex p-4 items-center'><label className='w-36' >Montant:</label><input className=' w-full rounded p-2  border-2' onChange={changeHandler}  type="number" name='amount' value={config.amount} /></div>
            <div className='text-center px-14'>
                <button onClick={submitHandler} className='w-full py-3 bg-primary text-white rounded-md'>{loader?<SpinnerLoader/>:'Ajouter'}</button>
            </div>
        </section>
    </Modal>
  }
</section>
  )
}

export default Config