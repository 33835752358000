import React from 'react'
import { StatCard } from '../components'
import { ShoppingBagIcon, ShoppingCartIcon, TruckIcon, UserIcon } from '@heroicons/react/24/outline'
import { appStateProxy } from '../core/stores'
import { useSnapshot } from 'valtio'

const StatisticCArds = () => {
  const {products,shops,orders,deliveries,users}=useSnapshot(appStateProxy)
  return (
    <section className='w-full grid grid-cols-4 gap-2'>
        <StatCard
        icon={ShoppingBagIcon}
        name={'Produits/Boutiques'}
        total={shops.length?shops.length:"0"}
        active={products.length?products.length:'0'}
        />  
        <StatCard
        icon={ShoppingCartIcon}
        name={'Commandes Livrées'}
        total={orders.length?orders.length:"0"}
        active={orders.filter(order => order.status === 'Livrée').length || "0"}
        />
        <StatCard
        icon={UserIcon}
        name={'Clients'}
        total={users.length?users.length:"0"}
        /> 
        <StatCard
        icon={TruckIcon}
        name={'Livreur'}
        total={deliveries.length?deliveries.length:"0"}
        />   
      </section>
  )
}

export default StatisticCArds