
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input, SpinnerLoader } from '../../components'

const Emailverify = () => {
  const [credentials,setCredentials]=useState<{email:string}>({email:''})
  const navigate=useNavigate()

  //Input change handler
  const changeHandler = (e:any)=>setCredentials((prev:any)=>({...prev,[e.target.name]:e.target.value}))

  // submit form handler
  const submitHandler =(e:any)=>{
    e.preventDefault();

  }

  useEffect(()=>{
  }
  ,[])
    return(
      <> 
    <div className='flex min-h-full  flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
        Recupération de mot de passe        </h2>
        <div className='relative'><span className='absolute z-20 text-white'>Email or Password incorect</span></div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6 bg-" >
          <div className=''>
            <label htmlFor="email" className="block text-md font-medium leading-6 text-white">
            Email 
            </label>
            <Input
            IType='email'
            name='email'
            onChangeValue={changeHandler}
            value={credentials?.email}
            />
          </div>
          <div>
          <button
            className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm  "
            type='submit'
            onClick={submitHandler}
            >
            {false?<SpinnerLoader/>:'Envoyer'}
          </button>
        </div>
      </form>
      <p className="mt-10 text-center text-sm text-gray-400">
        <Link className=' text-light' to={'/'}>Connexion</Link>
      </p>
      </div>
    </div>
    </>  
    )
    }
export default Emailverify