import React, { useState } from 'react'
import { Input, SpinnerLoader } from '../components'
import { IGiftcard } from '../core/models'
import { generateGiftcard } from '../core/stores'

const AddGiftCArd = ({setDisplay,setId}:any) => {
  const [loader,setLoader]=useState<boolean>()
  const [addGiftcard,setAddgiftcard]=useState<Pick<IGiftcard,"amount"|"quantity"|"name">>({
    name: '',
    quantity: 1,
    amount: 0,
  })

  const changeHandler=(e:any)=>setAddgiftcard((prev:any)=>({...prev,[e.target.name]:e.target.value}))
  const canceledHandler = () => {
    setDisplay((prev: any) => !prev);
    setId(()=>({id:''}))
  };
  const submitHandler = async () => {
    if (
      loader ||
      !addGiftcard?.amount ||
      !addGiftcard.quantity ||
      !addGiftcard.name 
    )
      return;
    setLoader((prev: any) => !prev);
    try {  
      console.log(addGiftcard);  
      await generateGiftcard(addGiftcard)
      setAddgiftcard(() => ({
        name: "",
        quantity: 1,
        amount: 0,
      }));
      canceledHandler()
    } catch (error: any) {}
    setLoader((prev: any) => !prev);
  };
  return (
    <section className="bg-white rounded-md p-4 space-y-4 min-w-[40%]">
    <div className="text-right">
      <h3 className="cursor-pointer" onClick={canceledHandler}>
        Annuler
      </h3>
    </div>
    <section className="space-y-4 p-4">
      <div className="flex space-x-4">
        <label className="w-1/3">Designation</label>
        <Input
          IType="text"
          name="name"
          onChangeValue={changeHandler}
          value={addGiftcard?.name}
        />
      </div>
      <div className="flex space-x-4">
        <label className="w-1/3">Quantité</label>
        <Input
          IType="number"
          name="quantity"
          onChangeValue={changeHandler}
          value={addGiftcard?.quantity}
        />
      </div>
      <div className="flex space-x-4">
        <label className="w-1/3">Montant</label>
        <Input
          IType="number"
          name="amount"
          onChangeValue={changeHandler}
          value={addGiftcard?.amount}
        />
      </div>
  
      <div className="text-center px-20">
        <button
          onClick={submitHandler}
          className="bg-primary w-full text-white py-3 px-10 rounded font-bold"
        >
          {loader ? <SpinnerLoader /> : "Générer"}
        </button>
      </div>
    </section>
  </section>
  )
}

export default AddGiftCArd