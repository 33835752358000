import React from 'react'

type IOrderCard={
    image: string,
    name: string,
    quantity: number|string,
    unitPrice: number,
}
const OrderCard = (props:IOrderCard) => {
  return (
    <section className='rounded-md bg-white p-4 space-y-4 border'>
        <div className='flex justify-center'><img className='w-48 h-48' src={props.image} alt={props.name} /></div>
        <div>
            <div className='flex'><label className='w-32'>Designation</label><p>{props.name}</p></div>
            <div className='flex'><label className='w-32'>Quantité</label><p>{props.quantity}</p></div>
            <div className='flex'><label className='w-32'>Prix uinitaire</label><p className='text-primary'>{props.unitPrice}</p></div>
        </div>
    </section>
  )
}

export default OrderCard