import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import SpinnerLoader from "./SpinnerLoader";
import { appStateProxy, updateOrder } from "../core/stores";
import { serverTimestamp } from "firebase/firestore";
import { useSnapshot } from "valtio";
import { IOrder } from "../core/models";
import { OrderCard } from ".";
import { notifyDelivery } from "../core/stores/delivery";

const EditOrder = ({ id, setId, setDipaly }: any) => {
  const { orders, users, deliveries } = useSnapshot(appStateProxy);
  const curentOrder = orders.find((ord) => ord.id === id);
  const [order, setOrder] = useState<any>();
  const [newdelivery, setNewDelivery] = useState<{ deliveryId: string }>({
    deliveryId: "",
  });

  const changeNewDeliveryHandler = (e: any) =>
    setNewDelivery((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  const [modal, setModal] = useState<boolean>();
  const [loader, setLoader] = useState<boolean>();
  const canceledHandler = () => {
    setDipaly((prev: any) => !prev);
    setId(() => ({ id: "" }));
  };
  const addDeliveryHandler = async () => {
    if (loader) return;
    setLoader((prev: any) => !prev);
    try {
      if (order?.status === "En attente") {
        await updateOrder(id, {
          deliveryId: newdelivery.deliveryId,
          status: "En cours",
          acceptedAt: serverTimestamp(),
        });
        if (!curentOrder) return
        notifyDelivery(newdelivery.deliveryId, 
          `commande ${id}`, `Veuillez effectuer la livraison de produits:${curentOrder.cart.map(item => '\n- ' + item.name  + ': ' + item.quantity + 'unité(s)').join(' ')}.\n\n Numero à contacter ${curentOrder.phoneNumber} \n\n ${curentOrder?.method==="WALLET"?'Déjà payé': 'Récuper *' + curentOrder.total + ' F CFA* à la livraison'}`, 
          {name: curentOrder.address.name || '', latitude: curentOrder.address.latitude, longitude: curentOrder.address.longitude, address: curentOrder.address.description});
        setDipaly((prev: any) => !prev);
      } else if (order?.status === "En cours") {
        await updateOrder(id, {
          deliveryId: newdelivery.deliveryId,
          status: "Livrée",
          delivredAt: serverTimestamp(),
        });
        setDipaly((prev: any) => !prev);
      }
    } catch (error: any) {}
    setLoader((prev: any) => !prev);
    setModal((prev: any) => !prev);
  };
  const cancelOrderHandler = async () => {
    if (loader || order?.status === "Livrée") return;
    setLoader((prev: any) => !prev);
    try {
      await updateOrder(id, {
        status: "Annulée",
        acceptedAt: serverTimestamp(),
      });
      setLoader((prev: any) => !prev);
      setDipaly((prev: any) => !prev);
    } catch (error) {
      setLoader((prev: any) => !prev);
    }
  };
  useEffect(() => {
    setOrder(() => ({ ...orders.find((ord) => ord.id === id) }));
    deliveries &&
      setNewDelivery((prev: any) => ({
        ...prev,
        deliveryId: deliveries[0]?.id,
      }));
  }, [deliveries]);
  return (
    <section className="bg-white p-4 rounded-md space-y-4 h-[90%] select-none min-w-[60%] overflow-y-auto">
      <div className="flex justify-end p-2">
        <XMarkIcon onClick={canceledHandler} className="w-8 cursor-pointer" />
      </div>
      <section className="space-y-8">
        {/* Panier */}
        <div className="space-y-4">
          <h2 className="text-2xl font-bold">Panier</h2>
          <div className="flex flex-wrap gap-2 p-2">
            {curentOrder?.cart.map((cart, idx) => (
              <OrderCard key={idx} image={cart.images} name={cart.name} quantity={cart.quantity} unitPrice={cart.unitPrice} />
            ))}
          </div>
          <div className="flex">
              <label className="w-32 font-bold">Boutique:</label>
              <p>{curentOrder?.shopName}</p>
            </div>
          <div className="flex">
              <label className="w-32 font-bold">Total:</label>
              <p>{curentOrder?.total}</p>
            </div>
        </div>
        {/* Payment */}
        <div className="space-y-2">
          <h2 className="text-2xl font-bold">Methode de payement </h2>
          {
            curentOrder?.method==="WALLET"?
            <div>
              <p className="text-green-400">Règlé par portefeuille</p>
            </div>
            :
            <div>
              <p>À payer à la livraison</p>
            </div>
          }
          <div className="flex flex-wrap">
      
          </div>
        </div>
        {/*Customer  Detail */}
        <section>
          <h2 className="text-2xl font-bold">Détail Client</h2>
          <section>
            <div className="flex">
              <label className="w-36">Adress:</label>
              <p>{curentOrder?.address.description}</p>
            </div>
            <div className="flex">
              <label className="w-36">Contact:</label>
              <p>{curentOrder?.phoneNumber}</p>
            </div>
          </section>
        </section>
      </section>
      {order?.status === "En attente" && (
        <div className="space-y-4">
          <h3>Ajout de livreur</h3>
          <select
            onChange={changeNewDeliveryHandler}
            name="deliveryId"
            className="min-w-[300px] px-2 py-3 outline-none bg-white border"
          >
            {deliveries.map((delivery, idx: any) => (
              <option
                key={idx}
                value={delivery.id}
              >{`${delivery.firstName} ${delivery.lastName}`}</option>
            ))}
          </select>
        </div>
      )}
      {order?.status === "En cours" && (
        <h2 className="w-full text-center font-bold text-xl pb-4">
          Livrer la commande
        </h2>
      )}
      <div className="flex gap-4">
        <button
          onClick={cancelOrderHandler}
          className="p-4 bg-red-500 rounded-md text-white w-full"
        >
          {loader ? <SpinnerLoader /> : "Annuler"}
        </button>
        {order?.status === "En attente" && (
          <button
            onClick={addDeliveryHandler}
            className="p-4 bg-primary rounded-md text-white w-full"
          >
            {loader ? <SpinnerLoader /> : "Livrer"}
          </button>
        )}
        {order?.status === "En cours" && (
          <button
            onClick={addDeliveryHandler}
            className="p-4 bg-green-500 rounded-md text-white w-full"
          >
            {loader ? <SpinnerLoader /> : "Confirmer Livraison"}
          </button>
        )}
      </div>
    </section>
  );
};

export default EditOrder;
