// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getMessaging } from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBP3cX6cyX8dlr0hiqK-qHhwhTroKaigcw",
  authDomain: "adnoc-voyager.firebaseapp.com",
  projectId: "adnoc-voyager",
  storageBucket: "adnoc-voyager.appspot.com",
  messagingSenderId: "203322476622",
  appId: "1:203322476622:web:f7bd4e8cffb8f987dd5b1f",
  measurementId: "G-J8C1ZE5FM0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);

export {
    app,
    // analytics,
    auth,
    firestore,
    storage,
    messaging
}
