import React, { useEffect, useState } from "react";
import {
  DelBtn,
  EditBtn,
  EditOrder,
  Header,
  Modal,
  SpinnerLoader,
  Theader,
} from "../../components";
import { commandeHeader } from "../../constant";
import { Link } from "react-router-dom";
import { useSnapshot } from "valtio";
import { appStateProxy, deleteCategory, updateOrder } from "../../core/stores";
import { serverTimestamp } from "firebase/firestore";
import { XMarkIcon } from "@heroicons/react/20/solid";

const Commandes = () => {
  const { orders, users, deliveries } = useSnapshot(appStateProxy);
  const [orderId, setOrderId] = useState<{ id: string }>({ id: "" });
  const [modal, setModal] = useState<boolean>();
  return (
    <section className="w-full h-full flex flex-col gap-4">
      <Header />,
      <table className="min-w-full divide-y divide-gray-300 bg-white">
        <Theader labels={commandeHeader} />
        <tbody className="bg-white">
          {orders.map((order, idx) => (
            <tr onClick={() => { setModal((prev: any) => !prev); setOrderId((prev: any) => ({ ...prev, id: order.id })) }} key={idx} className="even:bg-gray-50 cursor-pointer hover:bg-primary-500 select-none">
              <td className="font-bold whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                { order.shopName}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {new Date(order.createdAt?.seconds * 1000).toLocaleString(
                  "fr-FR"
                )}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {users.map(
                  (user) =>
                    user.id === order.userId &&
                    `${user.firstName} ${user.lastName}`
                )}
              </td>
              <td className="font-bold whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {order.total - order.reduction} F
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                {order?.method === 'WALLET' ?
                  <span className=" bg-primary px-4 py-1 rounded-l-full rounded-r-full"> Règlé par portefeuille</span>
                  : <span className="bg-red-700 px-4 py-1 rounded-l-full rounded-r-full">À payer à la livraison</span>}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {order.distance} km
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {deliveries.map(
                  (del) =>
                    del.id === order.deliveryId &&
                    `${del.firstName} ${del.lastName}`
                )}
              </td>
              <td
                className={`whitespace-nowrap px-3 py-4 text-sm font-bold ${order.status === "En cours"
                  ? "text-[#D06E0B]"
                  : order.status === "Livrée"
                    ? "text-[#0BD06E]"
                    : order.status === "Annulée"
                      ? "text-[#D00B0B]"
                      : "text-gray-900"
                  }`}
              >
                {order.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modal && (
        <Modal>
          <EditOrder id={orderId.id} setId={setOrderId} setDipaly={setModal} />
        </Modal>
      )}
    </section>
  );
};

export default Commandes;
