import { fsAdd, fsDelete, fsGet, fsListen, fsListenById, fsUpdate } from "../firebase";
import { IUser } from "../models";

const colName = "users"

const getUsers = async (): Promise<IUser[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenUsers = (cb: (Users: IUser[]) => void): void => {
    fsListen(colName, cb)
}

const listenUser = (id: string, cb: (User: IUser) => void): void => {
    fsListenById(colName, id, cb)
}


const addUser = async (data: Partial<IUser>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateUser = async (id: string, data: Partial<IUser>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteUser = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export { getUsers, addUser, updateUser, deleteUser, listenUser, listenUsers } 