import React, { useState } from "react";
import { DelBtn, EditBtn, Header, Modal, Theader } from "../../components";
import { codePromoHeader } from "../../constant";
import { useSnapshot } from "valtio";
import { appStateProxy } from "../../core/stores";
import { AddPromocode } from "../../sections";
import { deletePromoCode } from "../../core/stores/promoCode";

const PromoCode = () => {
  const [promoCodeId, setPromoCodeId] = useState<{ id: string }>({ id: "" });
  const { promocodes } = useSnapshot(appStateProxy);
  const [modal, setModal] = useState<boolean>();
  return (
    <section>
      <Header btn={true} setDisplay={setModal} />
      <table className="min-w-full divide-y divide-gray-300 bg-white">
        <Theader labels={codePromoHeader} />
        <tbody>
          {promocodes.map((promocode, idx) => (
            <tr key={idx}>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {promocode.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {promocode.discountType === 'percentage' ? `-${promocode.discountValue}%` :  `-${promocode.discountValue} F`}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                {promocode.isActive ? <span className=" px-2 py-1 rounded-l-full rounded-r-full bg-primary">Active</span> : <span className="px-2 py-1 rounded-l-full rounded-r-full bg-black">Non Active</span>}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                {promocode.limitPerUser ? promocode.limitPerUser : "∞"}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                {promocode.totalLimit ? promocode.totalLimit : "∞"}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {new Date(
                  promocode.limitDate.seconds * 1000
                ).toLocaleDateString("fr-FR")}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                {promocode.usageCount}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <span className='flex  gap-4'>
                <EditBtn
                  id={promocode.id}
                  setId={setPromoCodeId}
                  setDisplay={setModal}
                />
                <DelBtn delteMethode={deletePromoCode} id={promocode.id} />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modal && (
        <Modal>
          <AddPromocode setDisplay={setModal} id={promoCodeId.id} setId={setPromoCodeId} />
        </Modal>
      )}
    </section>
  );
};

export default PromoCode;
