import React, { useEffect, useState } from "react";
import { DelBtn, Header, Modal, Theader } from "../../components";
import { AddGiftCArd } from "../../sections";
import { giftcardsHeader } from "../../constant";
import { useSnapshot } from "valtio";
import { appStateProxy } from "../../core/stores";
import { deleteGiftcard } from "../../core/stores/giftcard";

const GiftCard = () => {
  const { giftcards } = useSnapshot(appStateProxy);
  const [filter,setFilter]=useState('Tout')
  const [modal, setModal] = useState<boolean>();
  const [giftcardId,setGiftcardId]=useState<{id:string}>()
  let filteredGiftcards=[...giftcards]
  let filterval=giftcards[0]?.name
  const filterHandler=(val:any,idx:any)=>{
    if(filterval===val?.name){
      return false
    }
    if(filterval!==val?.name){
      filterval=val?.name
      return true
    }
  }
useEffect(()=>{
  filteredGiftcards=filterval==="Tout"?[...giftcards]:giftcards.filter((gift)=>gift.name===filter)
},[filter])
  return (
    <section>
      <Header setDisplay={setModal} btn={true} />
      <section className="bg-white">
        <ul className="flex p-4 space-x-8">
          <li onClick={()=>setFilter(()=>"Tout")} className="font-bold cursor-pointer  bg-primary text-white px-4 py-1 rounded-l-full rounded-r-full">Tout</li>
          {/* <li onClick={()=>setFilter(()=>giftcards[0].name)}  className="font-bold cursor-pointer">{giftcards[0]?.name}</li> */}
          {giftcards.filter(filterHandler).map((gift,idx)=><li onClick={()=>setFilter(()=>gift.name)}  key={idx} className="font-bold cursor-pointer bg-gray-700 text-white px-4 py-1 rounded-l-full rounded-r-full">{gift?.name}</li>)}
        </ul>
      </section>
      <table className="min-w-full divide-y divide-gray-300 bg-white">
        <Theader labels={giftcardsHeader} />
        <tbody>
          {filteredGiftcards.map((giftcard:any, idx:any) => (
            <tr key={idx}>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {giftcard.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {giftcard.id}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {giftcard.code}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {giftcard.amount}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {giftcard.usedAt?new Date(giftcard.usedAt.seconds * 1000).toLocaleString("fr-FR"):'-'}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {giftcard.userName}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span className='flex  gap-4'>
                  <DelBtn delteMethode={deleteGiftcard} id={giftcard.id} />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modal && (
        <Modal>
          <AddGiftCArd setDisplay={setModal} setId={setGiftcardId} />
        </Modal>
      )}
    </section>
  );
};

export default GiftCard;
