import React, { useEffect, useState } from "react";
import { Input, SpinnerLoader } from "../components";
import { IPromoCode } from "../core/models";
import { addPromoCode, updatePromoCode } from "../core/stores/promoCode";
import { useSnapshot } from "valtio";
import { appStateProxy } from "../core/stores";

const AddPromocode = ({ setDisplay, id, setId }: any) => {
  const { promocodes } = useSnapshot(appStateProxy);
  const [loader, setLoader] = useState<boolean>();
  const promo: any = id && promocodes.find((prom) => prom.id === id);
  const [promocode, setPromocode] = useState<
    Pick<
      IPromoCode,
      "name" | "isActive" | "limitDate" | "limitPerUser" | "discountValue"
    >
  >({
    name: "",
    discountValue: 0,
    isActive: false,
    limitDate: "",
    limitPerUser: 0,
  });
  const changeHandler = (e: any) =>
    setPromocode((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  console.log(promocode);

  const canceledHandler = () => {
    setDisplay((prev: any) => !prev);
    setId(()=>({id:''}))
  };
  const submitHandler = async () => {
    if (
      loader ||
      !promocode.discountValue ||
      !promocode.limitDate ||
      !promocode.name ||
      !promocode.limitPerUser
    )
      return;
    setLoader((prev: any) => !prev);
    try {
        if(!promo){
            await addPromoCode(promocode);
          }
          if(id){
              await updatePromoCode(id,promocode)
            }
      setPromocode(() => ({
        name: "",
        discountValue: 0,
        isActive: false,
        limitDate: "",
        limitPerUser: 0,
      }));
    } catch (error: any) {}
    setLoader((prev: any) => !prev);
  };
  useEffect(() => {
    setPromocode((prev: any) => ({
      ...prev,
      name: promo?.name ? promo?.name : "",
      isActive: promo?.isActive ? promo?.isActive : "",
      discountValue: promo?.discountValue ? promo.discountValue : 0,
      limitDate:promo?.limitDate ? promo?.limitDate :'',
      limitPerUser:promo?.limitPerUser ? promo.limitPerUser :0
    }));
  }, []);
  return (
    <section className="bg-white rounded-md p-4 space-y-4 min-w-[40%]">
      <div className="text-right">
        <h3 className="cursor-pointer" onClick={canceledHandler}>
          Annuler
        </h3>
      </div>
      <section className="space-y-4 p-4">
        <div className="flex space-x-4">
          <label className="w-1/3">Designation</label>
          <Input
            IType="text"
            name="name"
            onChangeValue={changeHandler}
            value={promocode?.name}
          ></Input>
        </div>
        <div className="flex space-x-4">
          <label className="w-1/3">Nombre de reduction</label>
          <Input
            IType="number"
            name="discountValue"
            onChangeValue={changeHandler}
            value={promocode?.discountValue}
          ></Input>
        </div>
        <div className="flex space-x-4">
          <label className="w-1/3">Designation</label>
          <select
            onChange={changeHandler}
            name="isActive"
            className="w-full outline-none bg-white py-3 border-2 rounded"
          >
            <option value="false">Desactiver</option>
            <option value="true">Activer</option>
          </select>
        </div>
        <div className="flex space-x-4">
          <label className="w-1/3">Limete par utilisateur</label>
          <Input
            IType="number"
            name="limitPerUser"
            onChangeValue={changeHandler}
            value={promocode?.limitPerUser}
          ></Input>
        </div>
        <div className="flex space-x-4">
          <label className="w-1/3">Date limite</label>
          <Input
            IType="date"
            name="limitDate"
            onChangeValue={changeHandler}
            value={promocode?.limitDate}
          ></Input>
        </div>
        <div className="text-center px-20">
          <button
            onClick={submitHandler}
            className="bg-primary w-full text-white py-3 px-10 rounded font-bold"
          >
            {loader ? <SpinnerLoader /> : "Ajouter"}
          </button>
        </div>
      </section>
    </section>
  );
};

export default AddPromocode;
