import { orderBy, where } from "firebase/firestore";
import { fsAdd, fsDelete, fsGet, fsGetById, fsListen, fsUpdate } from "../firebase";
import { IPayment } from "../models";

const colName = "payments";

const getPayments = async (): Promise<IPayment[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenPayments = (shopId: string, cb: (Payments: IPayment[]) => void): void => {

    const query = []

    if (shopId) query.push(where("shopId", "==", shopId))
    query.push(orderBy('createdAt', 'desc'))

    fsListen(colName, cb, query)
}


const getPayment = (id: string): Promise<IPayment> => {
    const data: any = fsGetById(colName, id)
    return data
}

const addPayment = async (data: Partial<IPayment>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updatePayment = async (id: string, data: Partial<IPayment>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deletePayment = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export { getPayments, listenPayments, getPayment, addPayment, updatePayment, deletePayment }