import React, { useEffect, useRef, useState } from 'react'
import { navLists } from '../constant'
import { Link, useLocation } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../core/stores'
import { profile } from 'console'

const NavLink =({label,path, active}:any)=>{
  return (
    <Link to={path} className={active?'py-2 rounded-l-full text-xl pl-2 bg-white text-primary':'py-2 text-xl pl-2 text-white hover:rounded-l-full  hover:bg-white hover:text-primary'}>
          <span>{}</span>
          <span>{label}</span>
    </Link>
  )
}

const Sidebar = () => {
  const { profile } = useSnapshot(appStateProxy)
  const location = useLocation();
  const [links, setLinks] =  useState<any>([])
  useEffect(() => {
    // This function will be called whenever the route changes
    // console.log(`Path changed to ${location.pathname}`);
    setLinks(navLists
      .filter(item => profile?.role === 'ADMIN' ||  (profile?.role === "MANAGER" && item.path !=='/dashboard/parametres'))
      .map( item =>({...item,active:item.path === location.pathname})))
  }, [location, profile]);

  return (
    <div className='w-64 border shadow-lg flex flex-col gap-4 overflow-y-auto bg-primary py-4 pl-4' >
      {links.map((link:any,idx:any)=>(
        <NavLink key={idx} label={link.label} path={link.path} active={link.active}  />
      ))}
    </div>
  )
}

export default Sidebar