import React, { useEffect, useState } from 'react'
import { DoughnutChart, LinearChart, StatCard } from '../../components'
import { UserIcon } from '@heroicons/react/24/outline'
import { CommandeSection, StatisticCArds } from '../../sections'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../../core/stores'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'

const Dashboard = () => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBP3cX6cyX8dlr0hiqK-qHhwhTroKaigcw"
  })

  const { products, categories, monthlyStats, shops } = useSnapshot(appStateProxy)
  const [map, setMap] = React.useState<any>(null)
  const [center, setCenter] = useState({
    latitude: 12.36566,
    longitude: -1.533388,
    description: '',
    name: '',
  })
  const mapStyles = {
    height: '75vh',
    width: '100%',
  };


  const apiKey = 'AIzaSyBP3cX6cyX8dlr0hiqK-qHhwhTroKaigcw'


  useEffect(() => {
    // console.log(monthlyStats)
  }, [products, shops])


  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds({ lat: center.latitude, lng: center.longitude });
    // map.fitBounds(bounds);
    // navigator?.geolocation.getCurrentPosition(
    //   ({ coords: { latitude: lat, longitude: lng } }) => {
    //     setCenter({ latitude: lat, longitude: lng, description: '', name: '' })
    //   }
    // );
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])



  return (
    <section className='w-full h-full flex flex-col gap-8'>
      {/* Statistic card */}
      <StatisticCArds />
      <CommandeSection />
      <section className='w-full flex gap-2'>
        <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-2' >
          <div className='w-full bg-white shadow-md' >
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={6}
                center={{ lat: center.latitude, lng: center.longitude }}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {shops.map(shop => <MarkerF key={shop.id} position={{ lat: shop.address.latitude, lng: shop.address.longitude }} />)}

              </GoogleMap>) :
              <div className='bg-gray-500' style={mapStyles}>

              </div>
            }
          </div>

          <div className='w-full flex flex-col gap-4 bg-white shadow-md' >
            <div className='w-full bg-white h-full'>
              <LinearChart dataSets={[{ data: monthlyStats?.globalStatsArray || [], backgroundColor: ['#0061B8'], options: {} }]} labels={['january', 'feb', 'mach', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']} />
            </div>
            <div className='w-full p-4 flex items-center justify-center bg-white'>
              <DoughnutChart labels={[...products.map((prod) => prod.name)]}
                dataSets={
                  [{
                    data: [...products.map((prod) => prod.orderedQuantity)],
                    backgroundColor: [
                      '#0061B8', // bleu
                      '#00A8CC', // cyan
                      '#FF8C00', // orange
                      '#4CAF50', // vert
                      '#9C27B0', // violet
                      '#FFC107', // jaune
                      '#E91E63', // rose
                      '#795548', // brun
                      '#3F51B5', // indigo
                      '#FF5722'  // rouge
                    ]
                  }
                  ]
                } />
            </div>
          </div>

        </div>

      </section>
    </section >
  )
}

export default Dashboard