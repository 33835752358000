import React, { useState } from 'react'
import { settingsHeader } from '../../constant'
import { Adminstrator, Country, Config, Shops, Data } from '../../sections/settings'

const Parametres =  () => {
  const [menu, setMenu] = useState(0)
  const menus = ['Administrateur', 'Pays', 'Boutiques', 'Configuration', 'Données']
  const sections =  [
    <Adminstrator />,
    <Country />,
    <Shops />,
    <Config />,
    <Data />,
    // <Data />
  ]
  return (
    <section className=''>
      <ul className='flex bg-white py-4 px-2 space-x-4'>{
        menus.map( (label, index) => 
         <li key={index} onClick={() => setMenu(index)} 
         className={ index === menu?'p-4 cursor-pointer font-bold border rounded bg-primary text-white': 'p-4 cursor-pointer font-bold border rounded'} >
          {label}</li>)
      }
      </ul>
      { sections[menu] }
    </section>
  )
}

export default Parametres