import { proxy } from "valtio"
import {
   listenAdmin,
   listenAdmins,
   listenCategories,
   listenOrders,
   listenPayments,
   listenProducts,
   listenUser,
   listenUsers,
   updateAdmin
} from ".";
import { IState } from "../models";
import { listenDeliveries } from "./delivery";
import { listenCustomers } from "./customer";
import { listenShops } from "./shop";
import { listenSlides } from "./slide";
import { listenAdses } from "./ads";
import { listenPromoCodes } from "./promoCode";
import { listenGiftcards } from "./giftcard";
import { listenCountries } from "./country";
import { listenConfigs } from "./config";
import { groupMonthlyStats, listenShopStats, listenStats } from "./stats";
import { getFcmKey, listenForgroundNotifications } from "../firebase";


const appStateProxy = proxy<IState>({
   // properties
   profile: null,
   categories: [],
   products: [],
   admins: [],
   ads: [],
   customers: [],
   deliveries: [],
   orders: [],
   payments: [],
   shops: [],
   slides: [],
   users: [],
   promocodes: [],
   giftcards: [],
   countries: [],
   configs: [],
   monthlyStats: null,
   // methods
   init: () => {
      appStateProxy.setCategories();
      appStateProxy.setProducts();
      appStateProxy.setAdmins();
      appStateProxy.setAds();
      appStateProxy.setCustomers();
      appStateProxy.setDeliveries();
      appStateProxy.setShops();
      appStateProxy.setSlides();
      appStateProxy.setUsers();
      appStateProxy.setPromoCodes();
      appStateProxy.setGiftcards();
      appStateProxy.setCountries();
      appStateProxy.setConfigs();

   },
   setProfile: (uid) => {
      listenAdmin(uid, async (admin) => {
         const token = await getFcmKey()
         if (token && token !== admin.fcmToken) updateAdmin(uid, { fcmToken: token })
         listenForgroundNotifications((payload: any) => {
            new Notification(payload.notification.title, { body: payload.notification.body, icon: payload.notification.icon })
            window.alert(payload.notification.body)
         })
         appStateProxy.profile = admin
         appStateProxy.setStats(admin.shopId);
         appStateProxy.setOrders(admin.shopId);
         appStateProxy.setPayments(admin.shopId
            )
      })
   },
   setStats: (shopId?: string) => {
      // shopId ? listenShopStats(shopId, stats => appStateProxy.monthlyStats = groupMonthlyStats([stats]))
      //    : listenStats((stats) => {
      //       appStateProxy.monthlyStats = groupMonthlyStats(stats)
      //    })

      listenStats((stats) => {
         appStateProxy.monthlyStats = groupMonthlyStats(stats)
      })
   },
   setCategories: () => {
      listenCategories((categories) => appStateProxy.categories = categories)
   },

   setProducts: () => {
      listenProducts((products) => appStateProxy.products = products)
   },
   setAdmins: () => {
      listenAdmins((admins) => appStateProxy.admins = admins)
   },
   setAds: () => {
      listenAdses((adses) => appStateProxy.ads = adses)
   },
   setCustomers: () => {
      listenCustomers((customers) => appStateProxy.customers = customers)
   },
   setDeliveries: () => {
      listenDeliveries((deliveries) => appStateProxy.deliveries = deliveries)
   },
   setOrders: (shopId: string) => {
      listenOrders(shopId, (orders) => appStateProxy.orders = orders)
   },
   setPayments: (shopId: string) => {
      listenPayments(shopId, (payments) => appStateProxy.payments = payments)
   },
   setShops: () => {
      listenShops((shops) => appStateProxy.shops = shops)
   },
   setSlides: () => {
      listenSlides((slides) => appStateProxy.slides = slides)
   },
   setUsers: () => {
      listenUsers((users) => appStateProxy.users = users)
   },
   setPromoCodes: () => {
      listenPromoCodes((promocodes) => appStateProxy.promocodes = promocodes)
   },
   setGiftcards: () => {
      listenGiftcards((giftcards) => appStateProxy.giftcards = giftcards)
   },
   setCountries: () => {
      listenCountries((countries) => appStateProxy.countries = countries)
   },
   setConfigs: () => {
      listenConfigs((configs) => appStateProxy.configs = configs)
   }
})

export { appStateProxy }