import { fsAdd, fsDelete, fsGet, fsListen, fsListenById, fsUpdate } from "../firebase";
import { IPromoCode } from "../models";

const colName = "codepromos"

const getPromoCodes = async (): Promise<IPromoCode[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenPromoCodes = (cb: (promoCodes: IPromoCode[]) => void): void => {
    fsListen(colName, cb)
}

const listenPromoCode = (id: string, cb: (promoCode: IPromoCode) => void): void => {
    fsListenById(colName, id, cb)
}


const addPromoCode = async (data: Partial<IPromoCode>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updatePromoCode = async (id: string, data: Partial<IPromoCode>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deletePromoCode = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export { getPromoCodes, addPromoCode, updatePromoCode, deletePromoCode, listenPromoCode, listenPromoCodes } 