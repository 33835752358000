import { fsAdd, fsDelete, fsGet, fsListen, fsUpdate } from "../firebase";
import { uploadFile } from "../firebase/upload";
import { IProduct } from "../models";

const colName = "products"

const getProducts = async (): Promise<IProduct[]> => {
    const data: any[] = await fsGet(  colName)
    return data;
}

const listenProducts = (cb: (products: IProduct[]) => void): void => {
    fsListen(  colName, cb)
}

const addProduct = async (data: Partial<IProduct>): Promise<string> => {
    data.active = true;
    const { id } = await fsAdd(   colName, data)
    return id
}

const updateProduct = async (id: string,  data: Partial<IProduct>): Promise<void> => {
    await fsUpdate(  colName,  id, data)
}

const deleteProduct = async (id: string): Promise<void> => {
    await fsDelete(   colName, id)
}

const uploadProductFile=(prodId:string,file:any)=>{
    return uploadFile(`/products/${prodId}`, file)
}
export { getProducts, listenProducts, addProduct, updateProduct, deleteProduct,uploadProductFile} 