import React, { useEffect, useState } from 'react'
import { DelBtn, EditBtn, Modal, SpinnerLoader, Theader } from '../../components'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { deleteCountry } from '../../core/stores/country'
import { useSnapshot } from 'valtio'
import { appStateProxy, createAdmin } from '../../core/stores'
import { IAdmin } from '../../core/models'

const Adminstrator = () => {
  const {admins,shops}=useSnapshot(appStateProxy)
  const [admin,setAdmin]=useState<Pick<IAdmin,"email"|"lastName"|"firstName"|"phoneNumber"|'role'|"shopId">>({email:'',firstName:'',lastName:'',phoneNumber:'',role:'ADMIN',shopId:''})
  const [modal,setModal]=useState<boolean>()
  const [adminId,setAdminId]=useState<{id:string}>()
  const [loader,setLoader]=useState<boolean>()

  const changeHandler =(e:any)=>setAdmin((prev:any)=>({...prev,[e.target.name]:e.target.value}))

  const cancelHandler =()=>{
    setModal((prev:any)=>!prev)
    setAdmin(()=>({email:'',firstName:'',lastName:'',phoneNumber:'',role:'ADMIN',shopId:''}))
    setAdminId(()=>({id:''}))
  }
  const submitHandler =async()=>{
    if(!admin.email||!admin.firstName||!admin.lastName||!admin.phoneNumber||loader)return
    setLoader((prev:any)=>!prev)
    try {
      await createAdmin(admin)
      setModal((prev:any)=>!prev)
      setAdmin(()=>({email:'',firstName:'',lastName:'',phoneNumber:'',role:'ADMIN',shopId:''}))
    } catch (error) {
    }
    setLoader((prev:any)=>!prev)
  }
  useEffect(()=>{
    const newadmin=adminId?.id&&admins.find((adm)=>adm.id===adminId.id)
    newadmin&&setAdmin(()=>({email:newadmin.email,firstName:newadmin.firstName,lastName:newadmin.lastName,phoneNumber:newadmin.phoneNumber,role:newadmin.role,shopId:newadmin.shopId}))
  },[adminId?.id])
  return (
    <section>
    <div className='text-right p-4'>
        <button onClick={()=>setModal((prev:any)=>!prev)} className='bg-primary p-4 rounded-md text-white'>Ajouter un utilisateur</button>
    </div>
       <table className="min-w-full divide-y divide-gray-300 bg-white">
    <Theader labels={["Nom","Email","Avatar","Role"]} />
    <tbody className="bg-white">
        {admins.map((admin,idx)=>
            <tr   key={idx} className="even:bg-gray-50">
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{`${admin.firstName} ${admin.lastName}`}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{admin.email}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{admin.avatar?<img className='w-10 h-6' src={admin.avatar} alt={admin.email}/>:'...'}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{admin.role}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                  <EditBtn id={admin.id} setId={setAdminId} setDisplay={setModal}/>
                  <DelBtn  delteMethode={deleteCountry} id={admin.id}  />
                  </span></td>
          </tr>
        )}
    </tbody>
  </table>
  {
    modal&&
    <Modal>
        <section className='bg-white p-4 rounded min-w-[30%] space-y-4'>
            <span className='flex justify-end
            '>
                <XMarkIcon onClick={cancelHandler} className='cursor-pointer w-8 h-8'/>
            </span>
            <div className='flex p-4 items-center'><label className='w-32'>Nom:</label><input className='w-full rounded border-2 4 p-2 'onChange={changeHandler} name='lastName' value={admin.lastName} type="text" /></div>
            <div className='flex p-4 items-center'><label className='w-32' >Prénom:</label><input className=' w-full rounded p-2  border-2' onChange={changeHandler}  type="text" name='firstName' value={admin.firstName} /></div>
            <div className='flex p-4 items-center'><label className='w-32' >Email:</label><input className=' w-full rounded p-2  border-2' onChange={changeHandler}  type="email" name='email' value={admin.email} /></div>
            <div className='flex p-4 items-center'><label className='w-32' >Contact:</label><input className=' w-full rounded p-2  border-2' onChange={changeHandler}  type="tel" name='phoneNumber' value={admin.phoneNumber} /></div>
            <div className='flex p-4 items-center'>
              <label className='w-32'>Role:</label>
              <select value={admin.role} onChange={changeHandler} className=' w-full rounded p-2  border-2 bg-none' name='role'>
                <option value="ADMIN">Admin</option>
                <option value="MANAGER">{`Gerant(e)`}</option>
              </select>
            </div>
            {
              admin.role === 'MANAGER' &&
              <div className='flex p-4 items-center'>
              <label className='w-32'>Boutique:</label>
              <select value={admin.shopId} onChange={changeHandler} className=' w-full rounded p-2  border-2 bg-none' name='shopId'>
              {
                shops.map((shop,idx)=>
                <option key={idx} value={shop.id}>{shop.name}</option>
                )
              }
              </select>
            </div>
            }
            <div className='text-center px-14'>
                <button onClick={submitHandler} className='w-full py-3 bg-primary text-white rounded-md'>{loader?<SpinnerLoader/>:'Ajouter'}</button>
            </div>
        </section>
    </Modal>
  }
</section>
  )
}

export default Adminstrator