import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import { SpinnerLoader } from '../../components';


const Otp = () => {
  const [otp,setOtp]=useState<string>('')
  const navigate=useNavigate()
  const submitHandler=(e:any)=>{
    e.preventDefault()
    if(otp.length<6 )return
  }
  useEffect(()=>{
  }
  ,[])
  return (
<div className="flex  flex-col justify-center px-6 py-12 lg:px-8 ">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      
      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
        Entrer le code reçu 
      </h2>
    </div>

    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" >
        <div className='w-full flex justify-center'>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span></span>}
          renderInput={(props) => (
            <input {...props} className="border-b-2 text-4xl mx-2 max-w-[1.3em] outline-none border-gray-500 " />
          )}
        />
        </div>

        <div>
          <button
              className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm "
              type='submit'
            onClick={submitHandler}
          >
            {false?<SpinnerLoader/>:'Envoyer'}
          </button>
        </div>
      </form>
      <p className="mt-10 text-center text-sm text-white">
        <Link className=' text-light' to={'/'}>Connexion</Link>
      </p>
    </div>
</div>
  )
}

export default Otp