import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input, SpinnerLoader } from '../../components'

const ForgotPassword = () => {
  const [credentials,setCredentials] =useState<{password:string,newPassword:string}>({ password:'',newPassword:''}) 
  const [passwordStatus,setPasswordStatus] =useState<boolean>()
  const navigate=useNavigate()

  // Input change handler
  const changeHandler=(e:any)=>setCredentials((prev:any)=>({...prev,[e.target.name]:e.target.value}))

  // Submit form handler
  const submitHandler=(e:any)=>{
    e.preventDefault();
    if(!credentials.newPassword || !credentials.password )return
    if(credentials.newPassword!==credentials.password)return setPasswordStatus(()=>true)
  }

  useEffect(()=>{
  }
  ,[])
  return (
    <div className="flex  flex-col justify-center px-6 py-12 lg:px-8 ">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
        Changement de mot de passe
      </h2>
      {passwordStatus&&<p className='text-center text-danger'>Mot de passe incorrect </p>}
    </div>

    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" >
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
            Nouveau mot de passe
          </label>
          <Input
          IType='password'
          name='password'
          onChangeValue={changeHandler}
          value={credentials.password}
          />
        </div>

        <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
             Confirmer le mot de passe
            </label>
            <Input
            IType='password'
            name='newPassword'
            onChangeValue={changeHandler}
            value={credentials.newPassword}
            />
        </div>

        <div>
          <button
              className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm"
              type='submit'
            onClick={submitHandler}
          >
            {false?<SpinnerLoader/>:'Valider'}
          </button>
        </div>
      </form>
      <p className="mt-10 text-center text-sm text-gray-400">
        <Link className=' text-light' to={'/'}>Connexion</Link>
      </p>
    </div>
  </div>
  )
}

export default ForgotPassword