import { fsAdd, fsDelete, fsGet, fsListen, fsSet, fsUpdate } from "../firebase";
import { IGiftcard } from "../models";

const colName = "giftcards"

const  makeRandomPass = (length: number)  =>{
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
const  makeRandomId = (length: number)  =>{
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

const getGiftcards = async (): Promise<IGiftcard[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}
const listenGiftcards =(cb:(customers:IGiftcard[])=>void):void=>{
    fsListen(colName,cb)
}
const generateGiftcard = async (data: Partial<IGiftcard>): Promise<any> => {
    
    if (!data) return
    if (!data.quantity) return
    if (!data.amount) return
    const promises: any = []
    const amount = +data.amount
    new Array(+data.quantity).fill(null).forEach( _ => {
         // Generate random id
         const id =  makeRandomId(10)
        const code = makeRandomPass(6)
        console.log(id, code);
        return promises.push(fsSet(colName, id, { name: data.name, amount, code}))
    })

    return  await Promise.all(promises)

   
}

const updateGiftcard = async (id: string, data: Partial<IGiftcard>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteGiftcard = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export { getGiftcards, generateGiftcard, updateGiftcard, deleteGiftcard,listenGiftcards} 