import React, { useEffect, useState } from "react";
import {
  DelBtn,
  EditBtn,
  Modal,
  SpinnerLoader,
  Theader,
} from "../../components";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useSnapshot } from "valtio";
import { appStateProxy } from "../../core/stores";
import { addShop, deleteShop, updateShop } from "../../core/stores/shop";
import { IShop } from "../../core/models";

const Shops = () => {
  const [modal, setModal] = useState<boolean>();
  const [loader, setLoader] = useState<boolean>();
  const [secondStepForm, setSecondStepForm] = useState<{
    description: string;
    longitude: number;
    latitude: number;
  }>({ description: "", longitude: 0, latitude: 0 });
  const [shop, setShop] = useState<
    Pick<IShop, "address" | "countryId" | "name" | "description">
  >({
    address: { description: "", latitude: 0, longitude: 0 },
    countryId: "",
    name: "",
    description: "",
  });
  const [shopId, setShopId] = useState<{ id: string }>({ id: "" });
  const [steper, setSteper] = useState<1 | 2>(1);
  const { countries, shops } = useSnapshot(appStateProxy);

  const changeHandler = (e: any) =>
    setShop((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const secondFormHandler = (e: any) =>
    setSecondStepForm((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  const concellHandler = () => {
    setModal((prev: any) => !prev);
    setShopId(() => ({ id: "" }));
    setShop(() => ({
      address: { description: "", latitude: 0, longitude: 0 },
      countryId: "",
      name: "",
      description: "",
    }));
    setSecondStepForm(()=>({ description: "", longitude: 0, latitude: 0 }))
  };
  const firstStepHandler = () => {
    if (!shop.name || !shop.description) return alert("Competer les champs");
    setSteper(() => 2);
  };
  const secondStepSteptHandler = async () => {
    if (
      !secondStepForm.latitude ||
      !secondStepForm.longitude ||
      !secondStepForm.description ||
      loader
    )
      return;
    setLoader((prev: any) => !prev);
    try {
      if (!shopId.id) {
        const data = {
          address: {
            description: secondStepForm.description,
            latitude: +secondStepForm.latitude,
            longitude: +secondStepForm.longitude,
          },
          countryId: shop.countryId,
          name: shop.name,
          description: shop.description,
        };
        await addShop(data);
      }
      if (shopId.id) {
        const data = {
            address: {
              description: secondStepForm.description,
              latitude: +secondStepForm.latitude,
              longitude: +secondStepForm.longitude,
            },
            countryId: shop.countryId,
            name: shop.name,
            description: shop.description,
          };

        await updateShop(shopId.id,data);
      }
      setShop(() => ({
        address: { description: "", latitude: 0, longitude: 0 },
        countryId: "",
        name: "",
        description: "",
      }));
      setSteper(()=>1)
      setSecondStepForm(()=>({description:'',latitude:0,longitude:0}))
    } catch (error) {
      console.log(error);
    }
    setLoader((prev: any) => !prev);
    setModal((prev) => !prev);

  };
  useEffect(() => {
    const newShop = { ...shops.find((count) => count.id === shopId.id) };
    shopId.id &&
      setShop((prev: any) => ({
        ...prev,
        countryId: newShop.countryId,
        name: newShop.name,
        description: newShop.description,
      }));
      setSecondStepForm((prev:any)=>({...prev,description: newShop.address?.description,latitude:newShop.address?.latitude,longitude:newShop.address?.longitude}))
  }, [shopId.id]);
  return (
    <section>
      <div className="text-right p-4">
        <button
          onClick={() => setModal((prev: any) => !prev)}
          className="bg-primary p-4 rounded-md text-white"
        >
          Ajouter une boutique
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-300 bg-white">
        <Theader labels={["Nom", "Adress", "Pays"]} />
        <tbody className="bg-white">
          {shops.map((shop, idx) => (
            <tr key={idx} className="even:bg-gray-50">
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                {shop.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                {shop.address.description}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                {countries.map((country)=>country.id===shop.countryId&&`${country.name}`)}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span className="flex  gap-4">
                  <EditBtn
                    id={shop.id}
                    setId={setShopId}
                    setDisplay={setModal}
                  />
                  <DelBtn delteMethode={deleteShop} id={shop.id} />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modal && (
        <Modal>
          {steper === 1 && (
            <section className="bg-white p-4 rounded min-w-[30%] space-y-4">
              <span
                className="flex justify-end
        "
              >
                <XMarkIcon
                  onClick={concellHandler}
                  className="cursor-pointer w-8 h-8"
                />
              </span>
              <div className="flex p-4 items-center">
                <label className="w-36">Nom:</label>
                <input
                  className="w-full rounded border-2 4 p-2 "
                  onChange={changeHandler}
                  name="name"
                  value={shop.name}
                  type="text"
                />
              </div>
              <div className="flex p-4 items-center">
                <label className="w-36">Description:</label>
                <input
                  className=" w-full rounded p-2  border-2"
                  onChange={changeHandler}
                  type="tel"
                  name="description"
                  value={shop.description}
                />
              </div>
              <div className="flex p-4 items-center">
                <label className="w-36">Pays:</label>
                <select   onChange={changeHandler} name="countryId" className=" w-full rounded p-2  border-2 bg-white outline-none">
                  {countries.map((country) => (
                    <option value={country.id}>{country.name}</option>
                  ))}
                </select>
              </div>
              <div className="text-center px-14">
                <button
                  onClick={firstStepHandler}
                  className="w-full py-3 bg-primary text-white rounded-md"
                >
                  {"Suivant"}
                </button>
              </div>
            </section>
          )}
          {steper === 2 && (
            <section className="bg-white p-4 rounded min-w-[30%] space-y-4">
              <span
                className="flex justify-end
        "
              >
                <XMarkIcon
                  onClick={concellHandler}
                  className="cursor-pointer w-8 h-8"
                />
              </span>
              <div className="flex p-4 items-center">
                <label className="w-36">Adress:</label>
                <input
                  className="w-full rounded border-2 4 p-2 "
                  onChange={secondFormHandler}
                  name="description"
                  value={secondStepForm.description}
                  type="text"
                />
              </div>
              <div className="flex p-4 items-center">
                <label className="w-36">Latitude:</label>
                <input
                  className=" w-full rounded p-2  border-2"
                  onChange={secondFormHandler}
                  type="number"
                  name="latitude"
                  value={secondStepForm.latitude}
                />
              </div>
              <div className="flex p-4 items-center">
                <label className="w-36">Longitude:</label>
                <input
                  className=" w-full rounded p-2  border-2"
                  onChange={secondFormHandler}
                  type="number"
                  name="longitude"
                  value={secondStepForm.longitude}
                />
              </div>
              <div className=" flex text-center px-14 gap-4">
                <button
                  onClick={() => setSteper(() => 1)}
                  className="w-full py-3 bg-gray-400 text-white rounded-md"
                >
                  {"retour"}
                </button>
                <button
                  onClick={secondStepSteptHandler}
                  className="w-full py-3 bg-primary text-white rounded-md"
                >
                  {loader ? <SpinnerLoader /> : "Ajouter"}
                </button>
              </div>
            </section>
          )}
        </Modal>
      )}
    </section>
  );
};

export default Shops;
