import React, { useEffect, useState } from 'react'
import { DelBtn, EditBtn, Modal, SpinnerLoader, Theader } from '../../components';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { ICountry } from '../../core/models';
import { addCountry, deleteCountry, updateCountry, uploadCountrytFile } from '../../core/stores/country';
import { useSnapshot } from 'valtio';
import { appStateProxy } from '../../core/stores';

const Country = () => {
    const [modal,setModal]=useState<boolean>()
    const [loader,setLoader]=useState<boolean>()
    const [country,setCountry]=useState<Pick<ICountry,"flag"|"name"|"prefix">>({flag:'',name:'',prefix:''})
    const [file,setFile]=useState<any>('')
    const [countryId,setCountryId]=useState<{id:string}>({id:''})
    const {countries}=useSnapshot(appStateProxy)

    const changeHandler =(e:any)=>setCountry((prev)=>({...prev,[e.target.name]:e.target.value}))
    const changeFileHandler =(e:any)=>setFile(()=>e.target.files[0])
    const concellHandler =()=>{
        setModal((prev:any)=>!prev)
        setCountryId(()=>({id:''}))
        setCountry(()=>({flag:'',name:'',prefix:''}))
    }
    const submitHandler=async()=>{
        if(!file||!country.name||!country.prefix||loader)return
        setLoader((prev:any)=>!prev)
        try {
           if(!countryId.id){
            const res=await addCountry(country);
            const imagePath =await uploadCountrytFile(res,file)
            await updateCountry(res,{flag:imagePath})
           }
           if(countryId.id){
            if(file!==country?.flag){
                const image=await uploadCountrytFile(countryId.id,file)
                if(image)await updateCountry(countryId.id,{flag:image,prefix:country.prefix,name:country.name})
              }
            if(file===country?.flag)await updateCountry(countryId.id,{flag:file,prefix:country.prefix,name:country.name})
           }
            setFile(()=>'')
            setCountry(()=>({flag:'',name:'',prefix:''}))
        } catch (error) {
            console.log(error)
        }
        setLoader((prev:any)=>!prev)
        setModal((prev)=>!prev)
    }
    useEffect(()=>{
        const newCountry={...countries.find((count)=>count.id===countryId.id)}
        countryId.id&&setCountry((prev:any)=>({...prev,flag:newCountry.flag,name:newCountry.name,prefix:newCountry.prefix}))
    },[countryId.id])
  return (
    <section>
        <div className='text-right p-4'>
            <button onClick={()=>setModal((prev:any)=>!prev)} className='bg-primary p-4 rounded-md text-white'>Ajouter un pays</button>
        </div>
           <table className="min-w-full divide-y divide-gray-300 bg-white">
        <Theader labels={["Nom","prefix","Drapeau"]} />
        <tbody className="bg-white">
            {countries.map((country,idx)=>
                <tr   key={idx} className="even:bg-gray-50">
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{country.name}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{country.prefix}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"><img className='w-10 h-6' src={country.flag} alt={country.name}/></td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                      <EditBtn id={country.id} setId={setCountryId} setDisplay={setModal}/>
                      <DelBtn  delteMethode={deleteCountry} id={country.id}  />
                      </span></td>
              </tr>
            )}
        </tbody>
      </table>
      {
        modal&&
        <Modal>
            <section className='bg-white p-4 rounded min-w-[30%] space-y-4'>
                <span className='flex justify-end
                '>
                    <XMarkIcon onClick={concellHandler} className='cursor-pointer w-8 h-8'/>
                </span>
                <div className='flex p-4 items-center'><label className='w-20'>Nom:</label><input className='w-full rounded border-2 4 p-2 'onChange={changeHandler} name='name' value={country.name} type="text" /></div>
                <div className='flex p-4 items-center'><label className='w-20' >prefix:</label><input className=' w-full rounded p-2  border-2' onChange={changeHandler}  type="tel" name='prefix' value={country.prefix} /></div>
                <div className='flex p-4 items-center'><label className='w-20' >Drapeau:</label><input className='w-full rounded border-2  p-2 ' onChange={changeFileHandler} type="file"   /></div>
                <div className='text-center px-14'>
                    <button onClick={submitHandler} className='w-full py-3 bg-primary text-white rounded-md'>{loader?<SpinnerLoader/>:'Ajouter'}</button>
                </div>
            </section>
        </Modal>
      }
    </section>
  )
}

export default Country