import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/icons/logo.png'
import { listenAuthStatus } from '../core/firebase'
const AuthLayout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    listenAuthStatus((res) => {
      // console.log('State:',res)
      if (res && res.uid) navigate('/dashboard')
    })
  }, [])
  return (
    <section className='w-screen h-screen flex flex-col bg-adnocBg justify-center items-center gap-8'>
      <section className=' flex flex-col items-center justify-center gap-4 text-white'>

        {/* <h1 className='text-white font-extrabold text-4xl '>BSC Distribution</h1> */}
      </section>
      <section className='flex items-center justify-center  rounded-md min-w-[500px]'>
        <div className='bg-white h-full w-full flex flex-col justify-center'>
          <img src={logo} alt='logo' className='' />
        </div> 
        <div className='h-full bg-primary w-full text-white'>
          <Outlet />
        </div>

      </section>
    </section>
  )
}

export default AuthLayout