import React, { useState } from 'react'
import { Input, SpinnerLoader } from '../components'
import { useSnapshot } from 'valtio'
import { appStateProxy, uploadCatFile } from '../core/stores'
import { IAds } from '../core/models'
import { addAds, updateAds } from '../core/stores/ads'

const AddAds = ({setDisplay,id,setId}:any) => {

  const [file,setFile]=useState<any>(null)
  const [loader,setLoader]=useState<boolean>()
  const {ads}=useSnapshot(appStateProxy)
  const newAds=id&&ads.find((ad) =>ad.id===id)
  const [advirt,setAdvirt] =useState<Pick<IAds,"name" |"picture"|"order"|"contact">>({name:'', picture:'',contact:'',order:0})

  const cancelledHandler = () => {
    setDisplay((prev: any) => !prev);
    setId((prev: any) => ({ ...prev, id: "" }));
  };

  const changeFileHandler=(e:any)=>setFile(()=>e.target.files[0])


  const changeHandler = (e:any) =>setAdvirt((prev:any)=>({...prev,[e.target.name]:e.target.value})) 

  // submit handlers
  const submitHandler = async () => {
    console.log(file);
    if ( !advirt.contact || loader || !file) return;
    try {
      setLoader(() => true);
      if (!newAds) {
        const res = await addAds(advirt);
        const image = await uploadCatFile(res, file);
        if (image) await updateAds(res, { picture:image});
      }
      if (id) {
        if (file !== newAds?.image) {
          const image = await uploadCatFile(id, file);
          if (image)
            await updateAds(id, {
              name: advirt.name,
              picture: image,
              contact: advirt.contact,
              order:advirt.order
            });
        }
        if (file === newAds?.picture)
          await updateAds(id, {
            picture: file,
            contact: advirt.contact,
            order:advirt.order
          });
      }
      setDisplay((prev: any) => !prev);
      setId((prev: any) => ({ ...prev, id: "" }));
      setAdvirt(() => ({ name: "", picture: "", contact: "", order:0 }));
    } catch (e: any) {
      setLoader(() => false);
      console.log(e.message);
    }}
  return (
    <section className='bg-white flex flex-col p-4 rounded-lupg gap-3'>
    <span onClick={cancelledHandler} className='text-right m-3 cursor-pointer select-none'>annuler</span>
    
    <div className='w-full flex items-center gap-2'>
      <label>Titre:</label>
      <Input
      IType='text'
      name='name'
      value={advirt.name}
      onChangeValue={changeHandler}
      />
    </div>
    
    <div className='w-full flex items-center gap-2'>
      <label>Contact:</label>
      <Input
      IType='telephone'
      name='contact'
      value={advirt.contact}
      onChangeValue={changeHandler}
      />
    </div>
    <div className='w-full flex items-center gap-2'>
      <label>Position:</label>
      <Input
      IType='number'
      name='order'
      value={advirt.order}
      onChangeValue={changeHandler}
      />
    </div>
 
     <div className='w-full border flex items-center justify-center py-4'>
        <input onChange={changeFileHandler}   type='file'/>
    </div> 
    <div className='w-full flex justify-center items-center px-10 py-2'>
      <button onClick={submitHandler} className='w-full rounded-lg bg-adnocBg py-2 text-xl text-white' >
        {loader?<SpinnerLoader/>:'Valider'}
      </button>
    </div>
  </section>
  )
}

export default AddAds